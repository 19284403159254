import Vue from 'vue'

import PortalsStore from '@stores/PortalsStore'
import { each, isString } from 'lodash'
import { ensureId } from '@helpers/DomHelper'
import { notifyError } from '@helpers/BugsnagHelper'

// Internal: Registers a portal using the specified configuration.
export async function registerPortal ({ component, ...portal }) {
  if (isString(component)) {
    const ComponentConfig = (await import(`@portals/${component}`)).default
    component = Vue.extend(ComponentConfig)
  }
  PortalsStore.registerPortal({ component, ...portal })
}

export function unregisterPortal (selector, getPortalName) {
  try {
    each(document.querySelectorAll(selector), element => PortalsStore.removePortal({ name: getPortalName(element) }))
  } catch (error) {
    notifyError(error)
  }
}

// Internal: Registers a portal if an element with the specified selector
// exists in the current page.
export function registerPortalIf (selector, getPortalAttrs) {
  try {
    each(document.querySelectorAll(selector), element => {
      ensureId(element, 'portal_target')
      registerPortal({ mountTo: `${selector}#${element.id}`, append: true, ...getPortalAttrs(element) })
    })
  } catch (error) {
    notifyError(error)
  }
}
