// CacheKey 2ca975673bffb399b6c20011c72203e9
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { request } from '@services/EvolveApiService'

export default {
  search: options =>
    request('post', '/vue/issue_panels/search', options),

  archive: options =>
    request('put', '/vue/issue_panels/:id/archive', options),

  unarchive: options =>
    request('put', '/vue/issue_panels/:id/unarchive', options),

  editCopyConfig: options =>
    request('get', '/vue/issue_panels/:id/edit_copy_config', options),

  updateCopyConfig: options =>
    request('patch', '/vue/issue_panels/:id/update_copy_config', options),

  copy: options =>
    request('patch', '/vue/issue_panels/:id/copy', options),

  create: options =>
    request('post', '/vue/issue_panels', options),

  new: options =>
    request('get', '/vue/issue_panels/new', options),

  edit: options =>
    request('get', '/vue/issue_panels/:id/edit', options),

  get: options =>
    request('get', '/vue/issue_panels/:id', options),

  update: options =>
    request('patch', '/vue/issue_panels/:id', options),
}
