import { registerAndGetStore } from '@helpers/StoreHelper'
import { removeBy } from '@helpers/ArrayHelper'
import { snakeCase } from 'lodash'

export const state = () => ({
  // Portals that are currently active.
  portals: [],
})

export const getters = {
  // Checks if a portal with the same name and target is already registered.
  isPortalRegistered (state) {
    return name => state.portals.some(portal => portal.name === name)
  },
}

export const mutations = {
  ADD_PORTAL (state, portal) {
    state.portals.push(portal)
  },
  REMOVE_PORTAL (state, { name }) {
    removeBy(state.portals, portal => portal.name === name)
  },
}

export const actions = {
  // Registers a MountingPortal in the current window.
  registerPortal ({ commit, getters }, { mountTo, name = snakeCase(mountTo), ...portal }) {
    if (!getters.isPortalRegistered(name)) {
      commit('ADD_PORTAL', { name, mountTo, ...portal })
    }
    return name
  },
  // Removes a MountingPortal from the current window.
  removePortal ({ commit, getters }, { name }) {
    if (getters.isPortalRegistered(name)) commit('REMOVE_PORTAL', { name })
  },
}

export default registerAndGetStore('portals', { state, getters, mutations, actions })
