import '@evolve/BugsnagIntegration'

import Vue from 'vue'
import store from '@evolve/store'

import '@evolve/registerDirectives'
import '@evolve/registerFilters'
import '@evolve/registerGlobalComponents'
import '@evolve/registerGlobalTranslations'

import AuthStore from '@stores/AuthStore'
import NavbarForBeta from '@components/NavbarForBeta'
import { retrieveJsonNavigationBar } from '@evolve/legacy/navbar'
import { showUserProfileSurveyIfApplies } from '@helpers/UserProfileSurveyModalHelpers'

// Public: This bundle allows rendering TheNavigationBar in classic layout pages
// as well as providing limited Vue functionality to old pages, such as modals
// like the ones under `modals/Help`.
document.addEventListener('DOMContentLoaded', () => {
  const el = document.querySelector('#navigation-bar')

  if (!el) return // Nothing to do here, we are probably on the sign in page.

  // We need to define this watch outside AuthStore.js.
  // Defining it there causes a bug in the mocha-webpack compile due a `supposed` circular dependency
  const unwatch = AuthStore.watch(
    'initialized',
    initialized => {
      if (!initialized) return
      unwatch()
      showUserProfileSurveyIfApplies(AuthStore)
    },
    { immediate: true }
  )

  retrieveJsonNavigationBar(el)

  // eslint-disable-next-line no-unused-vars
  const navbar = new Vue({
    el,
    store,
    render: h => h(NavbarForBeta),
  })
})
