import ModalManager from '@components/ModalManager'
import { parseJSON } from '@helpers/JsonHelper'
import { registerPortal, registerPortalIf, unregisterPortal } from '@helpers/PortalHelper'
import { snakeCase } from 'lodash'
import { switchToSlickUrl } from '@helpers/UrlHelper'

function registerTryNewPageLink (options = {}) {
  // Let's first unregister any previously loaded instance of the same portal
  unregisterPortal(
    '#vue-try-new-page',
    options.name
      ? () => options.name
      : e => snakeCase(`#vue-try-new-page#${e.id}`)
  )
  // Try new page link added to pages converted to vue
  registerPortalIf('#vue-try-new-page', el => {
    const to = options.slickPage ? switchToSlickUrl(options.slickPage) : parseJSON(el.dataset.route)
    return {
      component: 'TryNewPageLink',
      props: { to },
      targetTag: 'span',
      ...options,
    }
  })
}

// Privacy picker for interactions.
function registerInteractionPrivacyPicker () {
  registerPortalIf('.vue-interaction-privacy-picker', el => ({
    component: 'InteractionPrivacyPicker',
    props: { id: el.dataset.id, privacy: el.dataset.privacy, staffMembers: JSON.parse(el.dataset.staffMembers) },
  }))
}

function reregisterLegacyTableActions () {
  unregisterAlertManagementButton()
  registerAlertManagementButton()
}

// New alert management button for user management page
function registerAlertManagementButton () {
  registerPortalIf('.vue-alert-management-button', el => ({
    name: `alert-management-button-${el.dataset.id}`,
    component: 'User/AlertManagementButton',
    props: { id: el.dataset.id, name: el.dataset.name },
    append: false,
  }))
}

function unregisterAlertManagementButton () {
  unregisterPortal('.vue-alert-management-button', el => `alert-management-button-${el.dataset.id}`)
}

// Portals that need to be called again from legacy code.
window.deferredPortals = {
  // Used when staff interactions history is not cached.
  registerInteractionPrivacyPicker,
  // Used in Angular's try-out-new-page directive.
  registerTryNewPageLink,
  // Used after filtering searches
  reregisterLegacyTableActions,
}

// Allows to add Vue functionality to a legacy page by mounting Vue components
// in a predefined placeholder.
export function setupVuePortalsInLegacyPages ($vm) {
  registerPortal({ component: ModalManager, mountTo: '#vue-modals' })

  // Reward button for staff member page
  registerPortalIf('#vue-staff-actions', el => ({
    name: 'staff-reward-button',
    component: 'Recognitions/RewardButton',
    props: { id: el.dataset.id, name: el.dataset.name },
  }))

  // Call deferred portals for cases when the mounting elements are available on page load.
  registerTryNewPageLink()
  registerInteractionPrivacyPicker()
  reregisterLegacyTableActions()

  // Rounding assignment filter for admitted patient and rounding assignment page
  registerPortalIf('#vue-assignees-filter', el => ({
    component: 'RoundingAssigneesSelectMenu',
    append: false,
  }))

  // Block patient button for patient info admin page
  registerPortalIf('.vue-block-patient-button', el => ({
    component: 'BlockPatientButton',
    props: { id: el.dataset.id, name: el.dataset.name, phone: el.dataset.phone },
    append: false,
  }))
}
