<script>
import PortalsStore from '@stores/PortalsStore'
import { MountingPortal } from 'portal-vue'

export default {
  name: 'PortalManager',
  components: {
    MountingPortal,
  },
  computed: PortalsStore.mapState('portals'),
}
</script>

<template>
  <span class="portal-manager">
    <template v-for="portal in portals">
      <MountingPortal :key="portal.name" :name="portal.name" :mountTo="portal.mountTo" :targetTag="portal.targetTag" :append="portal.append">
        <component :is="portal.component" v-bind="portal.props"/>
      </MountingPortal>
    </template>
  </span>
</template>
