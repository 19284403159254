import IssuePanelsRequests from '@requests/IssuePanelsRequests'

// TODO: Remove once we migrate to vue
// Setup the modal listeners for non-Vue pages.
export function setupVueModalsInLegacyPages ($vm) {
  onClick($vm, '.js-issue-panel-copy-config', openIssuePanelClipboardConfigurationModal)
}

// Internal: Helper to add click listeners to elements in legacy pages.
function onClick ($vm, selector, handler) {
  const button = document.querySelector(selector)
  if (button) button.addEventListener('click', event => handler({ $vm, dataset: button.dataset }))
}

// Internal: Opens the copy configuration modal in the old issue panel page.
function openIssuePanelClipboardConfigurationModal ({ $vm, dataset }) {
  IssuePanelsRequests.get({ id: dataset.id }).then(issuePanel => {
    $vm.asyncOpenModal('IssuePanels/CopyConfigurationFormModal', { props: { issuePanel } })
  })
}
