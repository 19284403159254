<script>
import EvolveMessageCenter from '@components/EvolveMessageCenter'
import PortalManager from '@evolve/legacy/PortalManager'
import StatusPageWidget from '@components/StatusPageWidget'
import TheNavigationBar from '@components/TheNavigationBar'
// eslint-disable-next-line local/no-global-component-imports
import PageCroutonPlaceholder from '@components/global/PageCroutonPlaceholder'
import WindowStore from '@stores/WindowStore'

import { mountComponent } from '@helpers/ComponentHelper'
import { setupVueModalsInLegacyPages } from '@evolve/legacy/ModalsCompatibility'
import { setupVuePortalsInLegacyPages } from '@evolve/legacy/PortalsCompatibility'

import NavigationRouterPlugin from '@router/plugins/NavigationRouterPlugin'
NavigationRouterPlugin({ progressContainer: '#navbar-for-beta' })

// Public: This component allows rendering TheNavigationBar in classic layout
// pages as well as providing limited Vue functionality to old pages, such as
// modals like the ones under `modals/Help`.
export default {
  name: 'NavbarForBeta',
  components: {
    TheNavigationBar,
    EvolveMessageCenter,
    StatusPageWidget,
    PortalManager,
  },
  computed: {
    ...WindowStore.mapState('navbarHeight'),
  },
  watch: {
    navbarHeight () {
      // We need to adjust the absolutely-positioned SVG in the Question Order page.
      if (window.$ && window.$.adjustQuestionConnections) window.$.adjustQuestionConnections()
    },
  },
  mounted () {
    // We need to add it manually to avoid layout issues.
    const placeholder = mountComponent(this, PageCroutonPlaceholder)
    this.$el.parentNode.insertBefore(placeholder.$el, this.$el.nextSibling)

    setupVueModalsInLegacyPages(this)
    setupVuePortalsInLegacyPages(this)

    // For custom styling in Beta .scss files
    document.querySelector('body').classList.add('slick')
  },
}
</script>

<template>
  <div id="navbar-for-beta">
    <KeyboardShortcutsOverlay/>
    <TheNavigationBar/>
    <PortalManager/>
    <EvolveMessageCenter/>
    <StatusPageWidget/>
  </div>
</template>

<style lang="scss">
@import '~@style/_animations.scss';
@import '~@style/_fonts.scss';
@import '~@style/_nprogress.scss';

$navbar-margin-bottom: 32px;

html,
body {
  background-color: $bg-html-old;
}

#app {
  @include flex-column;

  height: 100%;
}

#header .alert,
.care-form-wrapper-page #main.container-fluid,
.care-form-iframe.preview {
  margin-top: 0;
}

#header .alert {
  margin-bottom: 0;
  padding-top: 8px;
}

// Mainly for the Feedback Form, to revert Bootstrap 3 styles.
.blur-area.mask.modal {
  color: $fc-html;
  display: block;
  font-size: $fs-html;

  .modal-header,
  .modal-footer,
  .modal-body {
    border: none;
    padding: 0;
  }

  .modal-container {
    font-size: inherit;
  }
}

// Mainly for the Feedback and SVC Forms, to revert Bootstrap 3 styles.
.navigation-bar .form-control,
.modal-container .form-control {
  border: none;
  box-shadow: none;
  font-weight: initial;
  height: auto;
  padding: 0;
}

.navigation-bar {
  button {
    font-weight: $fw-regular;
  }

  .small {
    font-size: inherit;
  }
}

.navigation-bar,
.keyboard-shortcuts-overlay {
  kbd {
    @include keyboard-key;
  }
}

.navbar {
  margin-bottom: 0;
}

.admin-content .breadcrumb {
  margin-left: -15px;
}

.admin-content .back-button {
  left: -28px;
}

.back-form {
  margin-left: -28px;
}

#main {
  padding-left: $padding-horizontal-navbar;
  padding-right: $padding-horizontal-navbar;
  padding-top: $navbar-margin-bottom;
}

#main > .flexible-container {
  width: initial;
}
</style>
